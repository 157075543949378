import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import HomeServices from "../../Services/HomeServices";
import BackPageBtn from "../BackButton/BackPageBtn";

const CancelAppointment = () => {
  const location = useLocation();
  const [urgentData, setUrgentData] = useState([]);
  const [nonUrgentData, setNonUrgentData] = useState([]);
  const [cancelData, setCancelData] = useState([]);
  console.log("cancellAppointment76534", nonUrgentData, urgentData);
  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    {
      name: "First Name",
      selector: "firstName",
      sortable: true,
    },
    { name: "Last Name", selector: "lastName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    { name: "Status", selector: "isStatus", sortable: true },
  ];

  const fetchData = async () => {
    try {
      const response = await HomeServices.viewCancelAppointment();
      if (response && response?.status) {
        const cancelData = response?.data;
        const urgentAppointments = cancelData.filter(appointment => appointment.bookingAppointmentService.isUrgent === true);
        const nonUrgentAppointments = cancelData.filter(appointment => appointment.bookingAppointmentService.isUrgent === false);
        console.log("kjrh84736uihe", nonUrgentAppointments, urgentAppointments)

        const urgentFormattedData = urgentAppointments.reverse().map((ele, index) => ({
          sln: index + 1,
          firstName: ele?.firstName,
          lastName: ele?.lastName,
          email: ele?.email,
          mobileNumber: ele?.mobileNumber,
          isUrgent: ele?.bookingAppointmentService?.isUrgent,
          isStatus: ele?.status === false ? <p style={{ color: "red", fontWeight: "bold" }}>Cancelled</p> : " ",
        }));

        const nonUrgentFormattedData = nonUrgentAppointments.reverse().map((ele, index) => ({
          sln: index + 1,
          firstName: ele?.firstName,
          lastName: ele?.lastName,
          email: ele?.email,
          mobileNumber: ele?.mobileNumber,
          isUrgent: ele?.bookingAppointmentService?.isUrgent,
          isStatus: ele?.status === false ? <p style={{ color: "red", fontWeight: "bold" }}>Cancelled</p> : " ",
        }));

        setUrgentData(urgentFormattedData);
        setNonUrgentData(nonUrgentFormattedData);
      } else {
        // toast.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching cancel appointments:", error);
      toast.error("Error fetching cancel appointments");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            {/* <BackPageBtn backLink="/" /> */}

            <h1 style={{ color: "#ff9c00" }}>View Cancel Appointments</h1>
            <hr />
            <DataTable
              columns={columns}
              data={nonUrgentData}
              pagination
              searchable
            />


          </section>

          <section className="piechartsBox_area mt-5">
            {/* <BackPageBtn backLink="/" /> */}

            <h1 style={{ color: "#ff9c00" }}>View Urgent Cancel Appointments</h1>
            <hr />
            <DataTable
              columns={columns}
              data={urgentData}
              pagination
              searchable
            />


          </section>
        </div>
      </div>
    </>
  );
};

export default CancelAppointment;
{/* <DataTable
  columns={columns}
  data={urgentData}
  pagination
  searchable
/> */}