import React, { useEffect, useState } from 'react'
import HomeServices from '../../Services/HomeServices';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const InitialAppointment = () => {
    const [todayData, setTodayData] = useState([])
    const [modal, setModal] = useState(false);
    const [Data, setData] = useState('')

    console.log("kjfhd6785fvjh", Data)
    const columns = [
        { name: "SL No.", selector: "sln", sortable: true },
        // { name: "Date", selector: "date", sortable: true },
        { name: "Name", selector: "firstName", sortable: true },
        { name: "Email", selector: "email", sortable: true },
        { name: "Contact", selector: "mobileNumber", sortable: true },
        { name: "Appointment Date", selector: "bookedDate", sortable: true },
        { name: "Appointment Time", selector: "bookedTime", sortable: true },
        { name: "Action", selector: "action", sortable: true },
    ];

    const fetchTodayAppointment = async () => {

        const res = await HomeServices.getInitialBookignAppointment();
        if (res && res?.status) {
            let arr = res?.data?.reverse()?.map((ele, index) => {
                console.log("56465sdbjhksd", ele);
                return {
                    sln: index + 1,
                    // date: moment(ele?.createdAt).utc().format('DD-MM-YYYY'),
                    firstName: `${ele?.firstName} ${ele?.lastName}`,
                    email: ele?.email,
                    mobileNumber: ele?.mobileNumber,
                    bookedDate: moment(ele?.date).format('DD-MM-YYYY'),
                    bookedTime: ele?.BookingTime,
                    countryName: ele?.country,
                    action: (
                        <>
                            <span
                                title="View Appointment Detail"
                                onClick={() => { handleView(ele); setModal(true) }}
                            >
                                <i
                                    className="fa-regular fa-eye"
                                    style={{
                                        cursor: "pointer",
                                        padding: "5px",
                                        marginRight: "5px",
                                        fontSize: "1.5em",
                                        color: " #477DA5",
                                        backgroundColor: "#E9F3F7",
                                    }}
                                ></i>
                            </span></>
                    )
                };
            });
            setTodayData(arr)
        }
    }

    const handleView = (ele) => {
        if (ele) {
            const modifiedNote = ele?.note?.replaceAll('_', ' ');

            const modifiedData = {
                ...ele,
                note: modifiedNote
            };

            setData(modifiedData);
            return modifiedData;
        }
    }

    useEffect(() => {
        fetchTodayAppointment();
    }, [])
    return (
        <>
            {/* Today's Appointment */}
            <div className="d-flex justify-content-end mx-2">
                <div className="form-header">

                    <section className="piechartsBox_area">
                        <div><h1 style={{ fontSize: "25px" }}>Today's Appointment</h1></div>
                        <hr />
                        <DataTable
                            columns={columns}
                            data={todayData}
                            pagination
                            searchable
                        // customStyles={customStyles}
                        />
                    </section>

                    <Modal
                        size="lg"
                        isOpen={modal}
                        toggle={() => setModal(!modal)}
                    >
                        <ModalHeader toggle={() => setModal(!modal)}>
                            <h2 style={{ color: "#000" }}>
                                View Appointment
                            </h2>
                        </ModalHeader>
                        <ModalBody>
                            <section className="piechartsBox_area">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label htmlFor="firstName">Service Type</label>
                                            </td>
                                            <td>
                                                <span>
                                                    {Data?.isUrgent === true ? `${Data?.bookingAppointmentService?.service_type} (Urgent)` : `${Data?.bookingAppointmentService?.service_type}`}
                                                    {/* // {Data?.bookingAppointmentService?.service_type} */}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="firstName">First Name</label>
                                            </td>
                                            <td>
                                                <span>{Data?.firstName}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="lastName">Last Name</label>
                                            </td>
                                            <td>
                                                <span>{Data?.lastName}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="email">Email</label>
                                            </td>
                                            <td>
                                                <span>{Data?.email}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="mobileNumber">Mobile Code</label>
                                            </td>
                                            <td>
                                                <span>{Data?.mobileCode}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="mobileNumber">Mobile Number</label>
                                            </td>
                                            <td>
                                                <span>{Data?.mobileNumber}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="mobileNumber">Booked Date</label>
                                            </td>
                                            <td>
                                                <span>{Data?.date}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="mobileNumber">Booking Time</label>
                                            </td>
                                            <td>
                                                <span>
                                                    {Data?.BookingTime
                                                        ? new Date(
                                                            `2022-01-01T${Data?.BookingTime}`
                                                        ).getHours() < 12
                                                            ? `${Data?.BookingTime} AM`
                                                            : `${Data?.BookingTime} PM`
                                                        : ""}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="mobileNumber">Country</label>
                                            </td>
                                            <td>
                                                <span>{Data?.country}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label htmlFor="note">Note</label>
                                            </td>
                                            <td>
                                                <span>{Data?.note}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </ModalBody>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default InitialAppointment