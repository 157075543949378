import HttpClientXml from "../utils/HttpClientXml";

const login = async (data) =>{
  let endPoint = "login"
  return HttpClientXml.post(endPoint, data)
}
const getloginProfile = async () =>{
  let endPoint = "view-profile"
  return HttpClientXml.get(endPoint)
}

export default{
    login, getloginProfile
}
