import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import HomeServices from "../../Services/HomeServices";
import moment from "moment-timezone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, Tabs } from "react-tabs";
import "./All.css";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import BackPageBtn from "../BackButton/BackPageBtn";
import CustomLoader from "../loader/CustomLoader";

const Res = () => {
    const [timing_id, setTimeId] = useState({ timing_id: "" });
    const[loading, setIsLoading] = useState(false)
    const columns = [
        { name: "SL No.", selector: "sln", sortable: true },
        { name: "Blocked Date", selector: "block_date", sortable: true },
        { name: "Action", selector: "action", sortable: true },
      ];
      const [timeScheduleArr, setTimeScheduleArr] = useState([
        {
          serHead: "Timing",
          serTimeArr: [],
        },
      ]);
      const [check, setCheck] = useState("");
      const [modal, setModal] = useState(false);
      const [blockedDateTimeModal, setBlockedDateTimeModal] = useState(false);
      const [adminBlockedDate, setAdminBlockedDate] = useState([]);
    
      const [serviceDateTime, setServiceDateTime] = useState([]);
      const [blockDateTime, setBlockDateTime] = useState({
        block_date: "",
        block_time: [],
      });
      const [BlockedTime, setBlockedTime] = useState([]);
      console.log("Blo34543ckedTime", blockDateTime);
      const [blockedIds, setBlockIds] = useState([]);
      const [selectedTimes, setSelectedTimes] = useState([]);
      const [serviceType, setServiceType] = useState("");
      const [serType, setServType] = useState("");
      const [serType1, setServType1] = useState("");
      const [tabData, setTabData] = useState("phone");
      const [viewBlockdate, setViewBlockDate] = useState([]);
    
      console.log("timeScheduleAr654r", viewBlockdate);
    
      const [allTime, setAllTime] = useState([]);
      const location = useLocation();
      console.log("adsdasd", location?.state);
      const dateTimeField = {
        date: new Date(),
        time: "",
        value: location?.state,
      };
    
      const [dateTime, setDateTime] = useState(dateTimeField);
      const [modalA, setModalA] = useState(false);
      const navigate = useNavigate();
      const [selectedDate, setSelectedDate] = useState(null);
    
      const handleTimeSelection = (selectedTime) => {
        console.log("selectedTime", selectedTime);
        if (selectedTime && typeof selectedTime.time === "string") {
          setDateTime({
            ...dateTime,
            time: selectedTime._id,
          });
        } else {
          console.error("Invalid time format:", selectedTime);
        }
      };
    
      const fetchTime = async () => {
        try {
          const response = await HomeServices.getTime();
          // Call your API function
          console.log("khsg78", response);
          if (response && response?.status) {
            const timeSlots = response.data;
            setTimeScheduleArr([
              {
                serHead: "Timing",
                serTimeArr: timeSlots,
              },
            ]);
            console.log("getTimeResponse", response?.data);
          }
        } catch (error) {
          console.error("Error fetching warehouse data:", error);
        }
      };
    
      function openSetTimeModal() {
        setModalA(true);
      }

      const viewBlockByDateTime = async (date) => {
        let data = {
          block_date: date,
        };
        const res = await HomeServices.viewblockByDateAndTime(data);
        checkDate(date);
        console.log("6546esponse", res);
        if (res && res?.status) {
          if (res?.data?.length !== 0) {
            setBlockedTime(res?.data?.[0]?.bookingTime);
          } else {
            setBlockedTime([]);
          }
        }
      };
    
      const toggleSelectedTime = (timeId) => {
        if (selectedTimes.includes(timeId)) {
          setSelectedTimes((prevSelected) =>
            prevSelected.filter((id) => id !== timeId)
          );
        } else {
          setSelectedTimes((prevSelected) => [...prevSelected, timeId]);
        }
      };
    
      const onSUbmitReschedule = async () => {
        setIsLoading(true);
        console.log("mbjhgvvvvh7867rjgmn",selectedDate, moment()?.format("YYYY-MM-DD"))
        // return
        let data = { 
          date : selectedDate,
          timing_id: timing_id?.timing_id,
        };
        const res = await HomeServices.rescheduleBooking(location.state?.id, data);
        if (res && res.status) {
          toast.success("Appointment Reschedule Successfully");
    
          setTimeout(() => {
            navigate("/view-user-appointment");
          }, 2000);
        } else {
          toast.error("Unable to Reschedule Appointment");
        } 
        setIsLoading(false);
      }; 
    
      //   check blocked or not
      const checkedBook = (id) => {
        // console.log("BlockedTimeuse", BlockedTime);
        const check = BlockedTime?.find((item) => item?._id === id);
        if (check) {
          return true;
        } else {
          return false;
        }
      };
    
      const checkDate = (Date) => {
        console.log("ljndfinid23", selectedDate);
      };
    
      const handleClickTabs = async () => {
        let data = {
          service_type: tabData,
        };
        const res = await HomeServices.serviceTypeId(data);
        console.log("resNAme", res);
        if (res && res?.status) {
          setServiceType(res?.data?.[0]?._id);
        }
      };
    
      const availableByDatetime = async (date) => {
        console.log("first684987984");
        let data = {
          booking_serviceType_id: serviceType,
          date: date,
        };
        const res = await HomeServices.viewBookingTimeByDate(data);
        console.log("jksdfaqtbdk", res);
        if (res && res?.status) {
          setServiceDateTime(res?.data);
          const timeSlots = res.data;
          setTimeScheduleArr([
            {
              serHead: "Timing",
              serTimeArr: timeSlots,
            },
          ]);
        }
      };
    
      const fetchViewBlockDate = async () => {
        const res = await HomeServices.viewBlockdate();
        if (res && res?.status) {
          setViewBlockDate(res?.data);
        }
      };
    
      const blockDate = (date) => {
        const formatdate = moment(date).format("YYYY-MM-DD");
        const check = viewBlockdate.find((item) => item?.block_date === formatdate);
        if (check) {
          return true;
        } else {
          return false;
        }
      };
    
      const isWeekend = (date) => date.getDay() === 0 || blockDate(date);
    
      const handleChange = (e) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (e < currentDate) {
          toast.error("Please select a Current Date or Upcoming Date.");
        } else if (isWeekend(e)) {
          toast.error("You can't select a date on Saturday or Sunday!");
        } else {
          setBlockDateTime({
            ...blockDateTime,
            block_date: moment(e).format("YYYY-MM-DD"),
          });
          setSelectedDate(moment(e).format("YYYY-MM-DD"));
          viewBlockByDateTime(moment(e).format("YYYY-MM-DD"));
          availableByDatetime(moment(e).format("YYYY-MM-DD"));
        }
      };
    
      const tileDisabled = ({ date }) => isWeekend(date);
    
      const fetchBlockedDate = async () => {
        try {
          // Call your API function
          const response = await HomeServices.viewblockDateTime();
          console.log("Response986532", response);
          if (response && response?.status) {
            let arr = response?.data?.reverse()?.map((ele, index) => {
              console.log("56465sdbjhksd", ele);
              return {
                sln: index + 1,
                block_date: ele?.block_date,
                action: (
                  <>
                    <span
                      title="Delete Blocked date"
                      onClick={() => handleDelete(ele?._id)}
                    >
                      <i
                        className="fa-regular fa-trash-can"
                        style={{
                          cursor: "pointer",
                          marginRight: "10px",
                          padding: "5px",
                          fontSize: "1.5em",
                          color: "#C4554D",
                          backgroundColor: "#FAECEC",
                        }}
                      ></i>
                    </span>
                  </>
                ),
              };
            });
            setAdminBlockedDate(arr);
          }
        } catch (error) {
          console.error("Error fetching warehouse data:", error);
        }
      };
    
      useEffect(() => {
        fetchViewBlockDate();
        fetchBlockedDate();
        viewBlockByDateTime(moment().format("YYYY-MM-DD"));
      }, []);

      useEffect(()=>{
        if(serviceType){
        availableByDatetime(moment().format("YYYY-MM-DD"));
        }
      },[serviceType])
    
      useEffect(() => {
        handleClickTabs();
      }, [tabData]);
    
      useEffect(() => {
        if (selectedDate) {
          availableByDatetime(selectedDate);
        } else {
          fetchTime();
        }
      }, [selectedDate]);
    
      const tableProps = {
        columns,
        data: adminBlockedDate,
        fixedHeader: true,
        fixedHeaderScrollHeight: "300px",
      };
    
      const handleDelete = async (id) => {
        const res = await HomeServices.deleteBlockedDate(id);
        if (res && res?.status) {
          toast.success("Unblocked Successfully");
          fetchBlockedDate();
        } else if (res?.status === false) {
          toast.error("dfsdfsd");
        }
      };
    
      // const getAllBlockedDateTime = async () => {
      //   let data = {
      //     block_date: blockDateTime?.block_date,
      //     block_time: blockedIds,
      //   };
      //   const res = await HomeServices.deleteAllBlockedDate(data);
      //   if (res && res?.status) {
      //     toast.success("Time Deleted Successfully");
      //     window.location.reload();
      //   } 
      //   else {
      //     toast.error("Unable to delete time");
      //   }
      // };
  return (
    <>
    {loading && <CustomLoader/>}
    <div className="d-flex justify-content-end mx-2">
      <div className="form-header">
        <section className="piechartsBox_area">
          <div className="tablistDiv">
            <p className="seleSerText">Select a service</p>
            <div className="slectAftTab">
              <Tabs>
                <TabList>
                  <Tab onClick={() => setTabData("phone")}>Phone</Tab>
                  <Tab onClick={() => setTabData("in_person_office")}>
                    In Person - Office
                  </Tab>
                </TabList>
              </Tabs>
            </div>
          </div>
          <section className="predateSection">
            <div className="cust_container">
              <div className="row">
                <div className="col-12">
                  <p className="slYuHead">Reschedule appointment</p>
                  <div className="selectClndrDiv">
                    <Calendar
                      onChange={handleChange}
                      value={blockDateTime?.date}
                      tileDisabled={tileDisabled}
                      defaultValue={new Date()}
                    />
                  </div>

                  <div className="timeScheduleDiv">
                    {timeScheduleArr.map((value, index, arr) => {
                      console.log("timeScheduleArr", value);
                      return (
                        <div
                          style={{
                            marginBottom: `${
                              index === arr.length - 1 ? 0 : 18
                            }px`,
                          }}
                        >
                          <p className="sevMorHead">{value.serHead}</p>
                          <div
                            className="sevTimeAmDiv "
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {}}
                          >
                            {value.serTimeArr.length === 0 ? (
                              <div className="sevTimeAm">
                                <span>All Booked</span>
                              </div>
                            ) : (
                              value?.serTimeArr?.map((item, i) => {
                                console.log("itemfgs16", item);
                                return (
                                  <div
                                    key={i}
                                    className={`sevTimeAmBlock ${
                                      item?.isBooked === true
                                        ? "sevTimeAmSelectColor"
                                        : check === i + 1
                                        ? "sevTimeAmSelectColor"
                                        : checkedBook(item?._id) === true
                                        ? "sevTimeAmSelectColor"
                                        : "sevTimeAmColor"
                                    }`}
                                    onClick={() => {
                                        handleTimeSelection(item);
                                        setTimeId({
                                          ...timing_id,
                                          timing_id: item?._id,
                                        });
                                      }} 
                                  >
                                    <span
                                      className={
                                        checkedBook(item?._id) === true
                                          ? " "
                                          : ""
                                      }
                                      style={{
                                        margin: "5px",
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setBlockIds((prev) => [
                                          ...prev,
                                          item?._id,
                                        ]);
                                        toggleSelectedTime(item?._id);
                                      }}
                                    >
                                      {selectedTimes.includes(item?._id) && (
                                        <span>&#10003;</span>
                                      )}
                                      {item?.time}
                                      {checkedBook(item?._id) ? (
                                        <span
                                          className={
                                            checkedBook(item?._id)
                                              ? "disableDiv"
                                              : "sevTimeAmSelectColor"
                                          }
                                          style={{
                                            color: "#4d7902",
                                            fontWeight: "bold",
                                          }}
                                        > 
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {item?.isBooked === true ? (
                                      <span
                                        style={{
                                          color: " #4d7902",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {/* Booked */}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="serMDiv">
                    <p className="sevMorHead"></p>
                    <div>
                      <button
                        onClick={onSUbmitReschedule}
                        style={{ border: "none" }}
                        className="joinWaitLink m-2"
                      >
                        Reschedule
                      </button> 
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  </>
  )
}

export default Res