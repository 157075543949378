import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import HomeServices from "../../Services/HomeServices";
import { useNavigate } from "react-router-dom";
import BackPageBtn from "../BackButton/BackPageBtn";
import HttpClient from "../../utils/HttpClient";
import HttpClientXml from "../../utils/HttpClientXml";
import Swal from "sweetalert2";

const View_User_Contact = () => {
  const [contactData, setContactData] = useState();
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [viewId, setViewId] = useState("");
  const navigate = useNavigate();
  console.log("jsfkbgj9878", contactData);

  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    { name: "First Name", selector: "firstName", sortable: true },
    { name: "Last Name", selector: "lastName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    // { name: "Note", selector: "note", sortable: true },
    { name: "View", selector: "view", sortable: true },
    { name: "Delete", selector: "del", sortable: true },
  ];

  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewUserContact();
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          return {
            sln: index + 1,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            email: ele?.email,
            mobileNumber: ele?.mobileNumber,
            note: `${ele?.note.slice(0, 50)}...`,
            view: (
              <>
                {/* View Icon */}
                <span
                  title="View Contact Detail"
                  onClick={(e) => handleView(e, ele)}
                >
                  <i
                    className="fa-regular fa-eye"
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      marginRight: "10px",
                      fontSize: "1.5em",
                      color: " #477DA5",
                      backgroundColor: "#E9F3F7",
                    }}
                  ></i>
                </span>
              </>
            ),
            del: (
              <>
                {/* View Icon */}
                <span
                  title="Cancel Appointment"
                  onClick={() => handleViewCancel(ele?._id)}
                >
                  <i
                    className="fa-regular fa-rectangle-xmark"
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#C4554D",
                      backgroundColor: "#FAECEC",
                    }}
                  ></i>
                </span>
              </>
            ),
          };
        });
        setContactData(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleView = (e, ele) => {
    e.preventDefault();
    setViewId(ele?._id);
    navigate("/view-user-contatc-detail", { state: ele });
    setHighlightedRow(ele._id);
  };
  const handleViewCancel = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      console.log("result", result);
      if (result?.isConfirmed) {
        HomeServices.userContactDetails(id)
          .then((res) => {
            console.log("deleteBlogresponse", res);
            if (res && res.status) {
              toast.success("User Contact Deleted Successfully");
              fetchData();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((err) => {
            console.error("Error Cancelling Appointment:", err);
          });
      } else {
        console.log("Error while Cancellation");
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            {/* <BackPageBtn backLink="/" /> */}

            <h1 style={{ color: "#ff9c00" }}>View User Contact</h1>
            <hr />
            <DataTable columns={columns} data={contactData} pagination />
          </section>
        </div>
      </div>
    </>
  );
};

export default View_User_Contact;
