import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackPageBtn from "../BackButton/BackPageBtn";

const View_booked_Appointment_detail = () => {
  const [viewContactData, setViewContactData] = useState("");
  const location = useLocation();

  const handleNote = () => {
    // alert('vivek')
    const myNote = location?.state?.note
    console.log("jhfjhsbdkjs6546", location?.state?.note);
    const changeNote = myNote?.replaceAll('_', " ")
    console.log("sfgsdfgs45", changeNote)
    return changeNote
  }

  useEffect(() => {
    setViewContactData({
      ...location.state,
      note: handleNote(), // Update the note with the normalized version
    });
    // setViewContactData(location.state);
    // handleNote()
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            <BackPageBtn backLink="/view-user-appointment" />

            <h1 style={{ color: "#ff9c00" }}>View User Book Appointment Detail</h1>
            <hr />
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="firstName">Service Type</label>
                  </td>
                  <td>
                    <span>
                      {viewContactData?.bookingAppointmentService?.isUrgent === true ? `${viewContactData?.bookingAppointmentService?.service_type} (Urgent)` : viewContactData?.bookingAppointmentService?.service_type}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="firstName">First Name</label>
                  </td>
                  <td>
                    <span>{viewContactData?.firstName}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="lastName">Last Name</label>
                  </td>
                  <td>
                    <span>{viewContactData?.lastName}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="email">Email</label>
                  </td>
                  <td>
                    <span>{viewContactData?.email}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Mobile Code</label>
                  </td>
                  <td>
                    <span>{viewContactData?.mobileCode}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Mobile Number</label>
                  </td>
                  <td>
                    <span>{viewContactData?.mobileNumber}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Booked Date</label>
                  </td>
                  <td>
                    <span>{viewContactData?.date}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Booking Time</label>
                  </td>
                  <td>
                    <span>
                      {viewContactData?.BookingTime
                        ? new Date(
                          `2022-01-01T${viewContactData?.BookingTime}`
                        ).getHours() < 12
                          ? `${viewContactData?.BookingTime} AM`
                          : `${viewContactData?.BookingTime} PM`
                        : ""}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Country</label>
                  </td>
                  <td>
                    <span>{viewContactData?.country}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="note">Note</label>
                  </td>
                  <td>
                    <span>{viewContactData?.note}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
};

export default View_booked_Appointment_detail;
