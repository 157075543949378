import React, { useEffect, useState } from 'react'
import HomeServices from '../../Services/HomeServices';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";



const TodayAppointment = () => {
  const [todayData, setTodayData] = useState([])
  const [tomorrowData, setTomorrowData] = useState([])
  const [dayAfterTomorrowData, setDayAfterTomorrowData] = useState([])

  const [modal, setModal] = useState(false);
  const [Data, setData] = useState('')

  console.log("nbjhf78y87g", Data)
  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    // { name: "Date", selector: "date", sortable: true },
    { name: "Name", selector: "firstName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    { name: "Appointment Date", selector: "bookedDate", sortable: true },
    { name: "Appointment Time", selector: "bookedTime", sortable: true },
    { name: "Action", selector: "action", sortable: true },
  ];
 

  const fetchTodayAppointment = async () => {
    const res = await HomeServices.getTodayAppointment();
    if (res && res.status) {
      let todayAppointments = [];
      let tomorrowAppointments = [];
      let dayAfterTomorrowAppointments = [];
  
      res.data.reverse().forEach((ele, index) => {
        let bookedDate = moment(ele.date).format('DD-MM-YYYY');
        let appointment = {
          sln: index + 1,
          firstName: `${ele.firstName} ${ele.lastName}`,
          email: ele.email,
          mobileNumber: ele.mobileNumber,
          bookedDate: bookedDate,
          bookedTime: ele.timeData.time,
          countryName: ele.country,
          action: (
            <span
              title="View Appointment Detail"
              onClick={() => { handleView(ele); setModal(true) }}
            >
              <i
                className="fa-regular fa-eye"
                style={{
                  cursor: "pointer",
                  padding: "5px",
                  marginRight: "5px",
                  fontSize: "1.5em",
                  color: " #477DA5",
                  backgroundColor: "#E9F3F7",
                }}
              ></i>
            </span>
          )
        };
  
        let today = moment().format('DD-MM-YYYY');
        let tomorrow = moment().add(1, 'days').format('DD-MM-YYYY');
        let dayAfterTomorrow = moment().add(2, 'days').format('DD-MM-YYYY');
  
        if (bookedDate === today) {
          todayAppointments.push(appointment);
        } else if (bookedDate === tomorrow) {
          tomorrowAppointments.push(appointment);
        } else if (bookedDate === dayAfterTomorrow) {
          dayAfterTomorrowAppointments.push(appointment);
        }
      });
  
      setTodayData(todayAppointments);
      setTomorrowData(tomorrowAppointments);
      setDayAfterTomorrowData(dayAfterTomorrowAppointments);
    }
  } 

  const handleView = (ele) => {
    if (ele) { 
      const modifiedNote = ele?.note?.replaceAll('_', ' '); 

      const modifiedData = {
        ...ele,
        note: modifiedNote  
      };
  
      setData(modifiedData);
      return modifiedData;
    }
  }

  useEffect(() => {
    fetchTodayAppointment();
  }, [])

  return (
    <>
    {/* Today's Appointment */}
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">

          <section className="piechartsBox_area">
            <div><h1 style={{ fontSize: "25px" }}>Today's Appointment</h1></div>
            <hr />
            <DataTable
              columns={columns}
              data={todayData}
              pagination
              searchable
            // customStyles={customStyles}
            />
          </section>

          <Modal
            size="lg"
            isOpen={modal}
            toggle={() => setModal(!modal)}
          >
            <ModalHeader toggle={() => setModal(!modal)}>
              <h2 style={{ color: "#000" }}>
                View Appointment
              </h2>
            </ModalHeader>
            <ModalBody>
              <section className="piechartsBox_area">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="firstName">Service Type</label>
                      </td>
                      <td>
                        <span>
                          {Data?.serviceDetail?.service_type}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="firstName">First Name</label>
                      </td>
                      <td>
                        <span>{Data?.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="lastName">Last Name</label>
                      </td>
                      <td>
                        <span>{Data?.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="email">Email</label>
                      </td>
                      <td>
                        <span>{Data?.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Code</label>
                      </td>
                      <td>
                        <span>{Data?.mobileCode}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                      </td>
                      <td>
                        <span>{Data?.mobileNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booked Date</label>
                      </td>
                      <td>
                        <span>{Data?.date}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booking Time</label>
                      </td>
                      <td>
                        <span>
                          {Data?.timeData?.time
                            ? new Date(
                              `2022-01-01T${Data?.BookingTime}`
                            ).getHours() < 12
                              ? `${Data?.timeData?.time} AM`
                              : `${Data?.timeData?.time} PM`
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Country</label>
                      </td>
                      <td>
                        <span>{Data?.country}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="note">Note</label>
                      </td>
                      <td>
                        <span>{Data?.note}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </ModalBody>
          </Modal>

        </div>
      </div>

    {/* Tomorrow's Appointment */}
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">

          <section className="piechartsBox_area">
            <div><h1 style={{ fontSize: "25px" }}>Tomorrow's Appointment</h1></div>
            <hr />
            <DataTable
              columns={columns}
              data={tomorrowData}
              pagination
              searchable
            // customStyles={customStyles}
            />
          </section>

          <Modal
            size="lg"
            isOpen={modal}
            toggle={() => setModal(!modal)}
          >
            <ModalHeader toggle={() => setModal(!modal)}>
              <h2 style={{ color: "#000" }}>
                View Appointment
              </h2>
            </ModalHeader>
            <ModalBody>
              <section className="piechartsBox_area">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="firstName">Service Type</label>
                      </td>
                      <td>
                        <span>
                          {Data?.serviceDetail?.service_type}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="firstName">First Name</label>
                      </td>
                      <td>
                        <span>{Data?.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="lastName">Last Name</label>
                      </td>
                      <td>
                        <span>{Data?.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="email">Email</label>
                      </td>
                      <td>
                        <span>{Data?.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Code</label>
                      </td>
                      <td>
                        <span>{Data?.mobileCode}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                      </td>
                      <td>
                        <span>{Data?.mobileNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booked Date</label>
                      </td>
                      <td>
                        <span>{Data?.date}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booking Time</label>
                      </td>
                      <td>
                        <span>
                          {Data?.timeData?.time
                            ? new Date(
                              `2022-01-01T${Data?.BookingTime}`
                            ).getHours() < 12
                              ? `${Data?.timeData?.time} AM`
                              : `${Data?.timeData?.time} PM`
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Country</label>
                      </td>
                      <td>
                        <span>{Data?.country}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="note">Note</label>
                      </td>
                      <td>
                        <span>{Data?.note}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </ModalBody>
          </Modal>

        </div>
      </div>

      {/* DayAfterMorrow's Appointment */}
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">

          <section className="piechartsBox_area">
            <div><h1 style={{ fontSize: "25px" }}>Dayaftermorrow's Appointment</h1></div>
            <hr />
            <DataTable
              columns={columns}
              data={dayAfterTomorrowData}
              pagination
              searchable
            // customStyles={customStyles}
            />
          </section>

          <Modal
            size="lg"
            isOpen={modal}
            toggle={() => setModal(!modal)}
          >
            <ModalHeader toggle={() => setModal(!modal)}>
              <h2 style={{ color: "#000" }}>
                View Appointment
              </h2>
            </ModalHeader>
            <ModalBody>
              <section className="piechartsBox_area">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="firstName">Service Type</label>
                      </td>
                      <td>
                        <span>
                          {Data?.serviceDetail?.service_type}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="firstName">First Name</label>
                      </td>
                      <td>
                        <span>{Data?.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="lastName">Last Name</label>
                      </td>
                      <td>
                        <span>{Data?.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="email">Email</label>
                      </td>
                      <td>
                        <span>{Data?.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Code</label>
                      </td>
                      <td>
                        <span>{Data?.mobileCode}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                      </td>
                      <td>
                        <span>{Data?.mobileNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booked Date</label>
                      </td>
                      <td>
                        <span>{Data?.date}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Booking Time</label>
                      </td>
                      <td>
                        <span>
                          {Data?.timeData?.time
                            ? new Date(
                              `2022-01-01T${Data?.BookingTime}`
                            ).getHours() < 12
                              ? `${Data?.timeData?.time} AM`
                              : `${Data?.timeData?.time} PM`
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="mobileNumber">Country</label>
                      </td>
                      <td>
                        <span>{Data?.country}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="note">Note</label>
                      </td>
                      <td>
                        <span>{Data?.note}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </ModalBody>
          </Modal>

        </div>
      </div>
    </>
  )
}

export default TodayAppointment