import HttpClientXml from "../utils/HttpClientXml";

const addBlog = async (data) => {
  let endPoint = 'add-blog'
  return HttpClientXml.post(endPoint, data)
}

const getBlog = async (data) => {
  let endPoint = 'view-blog'
  return HttpClientXml.get(endPoint, data)
}

const updateBlog = async (id, data) => {
  let endPoint = `update-blog/${id}`
  return HttpClientXml.put(endPoint, data)
}

const deleteBlog = async (id) => {
  let endPoint = `delete-blog/${id}`
  return HttpClientXml.deletemethod(endPoint)
}

const viewUserContact = async (data) => {
  let endPoint = "view-contactus"
  return HttpClientXml.get(endPoint, data)
}

const viewAppointmentData = async (data) => {
  let endPoint = "view-bookinglist"
  return HttpClientXml.get(endPoint, data)
}

const viewUserEmail = async (data) => {
  let endPoint = "view-email"
  return HttpClientXml.get(endPoint, data)
}

const viewWaitListAppointment = async () => {
  let endPoint = "view-waitinglist"
  return HttpClientXml.get(endPoint)
}

const viewCancelAppointment = async () => {
  let endPoint = "cancelled-booking"
  return HttpClientXml.get(endPoint)
}

const cancelAppointmentById = async (id) => {
  let endPoint = `cancel-booking/${id}`
  return HttpClientXml.put(endPoint)
}

const getTime = async () => {
  let endPoint = "view-timing";
  return HttpClientXml.get(endPoint);
};

const getInitialBookignAppointment = async () => {
  let endPoint = "view-booking-initial-beforepayment";
  return HttpClientXml.get(endPoint);
};
// /view-booking-initial-beforepayment

const rescheduleBooking = async (id, data) => {
  let endPoint = `resecdule-booking/${id}`;
  return HttpClientXml.put(endPoint, data);
};

const viewBookingTimeByDate = async (data) => {
  let endPoint = "available-time-by-date";
  return HttpClientXml.post(endPoint, data);
};

const blockDateTime = async (data) => {
  let endPoint = "add-blockdatetime";
  return HttpClientXml.post(endPoint, data);
};

const viewblockDateTime = async () => {
  let endPoint = "view-blockdatetime";
  return HttpClientXml.get(endPoint);
};

const viewUrgentblockDateTime = async (id) => {
  let endPoint = "view-blockonlydate-urgent/" + id;
  return HttpClientXml.get(endPoint);
};

const viewblockByDateAndTime = async (data) => {
  let endPoint = "view-block-date-and-time";
  return HttpClientXml.post(endPoint, data);
};

const viewUrgentBlockByDateAndTime = async (data) => {
  let endPoint = "view-block-date-and-time-urgent";
  return HttpClientXml.post(endPoint, data);
};

const serviceTypeId = async (data) => {
  let endPoint = "servicewise-appointment";
  return HttpClientXml.post(endPoint, data);
};

const viewBlockdate = async (id) => {
  let endPoint = "view-blockdate/" + id;
  return HttpClientXml.get(endPoint);
};

const viewUrgentBlockdate = async () => {
  let endPoint = "view-blockdatetime-urgent";
  return HttpClientXml.get(endPoint);
};

const viewAllBlockedDateTime = async () => {
  let endPoint = "view-all-blockdatetime";
  return HttpClientXml.get(endPoint);
};

const viewAllUrgentAppointment = async () => {
  let endPoint = "view-urgent-book";
  return HttpClientXml.get(endPoint);
};

const deleteBlockedDate = async (id) => {
  let endPoint = `delete-blockdate-only/${id}`;
  return HttpClientXml.deletemethod(endPoint);
};

const urgentDeleteBlockedDate = async (id) => {
  let endPoint = `delete-blockdate-only-urgent/${id}`;
  return HttpClientXml.deletemethod(endPoint);
};

// delete user contact details by id
const userContactDetails = async (id) => {
  let endPoint = `delete-contactus/${id}`;
  return HttpClientXml.deletemethod(endPoint);
};

const deleteAllBlockedDate = async (data) => {
  let endPoint = "unblock-date-time";
  return HttpClientXml.put(endPoint, data);
};

const urgentDeleteAllBlockedDate = async (data) => {
  let endPoint = "unblock-date-time-urgent";
  return HttpClientXml.put(endPoint, data);
};

const getTodayAppointment = async () => {
  let endPoint = "get-all-booking-date";
  return HttpClientXml.get(endPoint);
};

const urgentBlockDateTime = async (data) => {
  let endPoint = "add-blockdatetime-urgent";
  return HttpClientXml.post(endPoint, data);
};

// add-blockdatetime-urgent
export default {
  rescheduleBooking, getTime, addBlog, getBlog, updateBlog, deleteBlog, viewUserContact, viewUserEmail, viewWaitListAppointment, viewAppointmentData, viewCancelAppointment, cancelAppointmentById, viewBookingTimeByDate, blockDateTime, viewblockDateTime
  , viewblockByDateAndTime, viewUrgentBlockByDateAndTime, serviceTypeId, viewBlockdate, deleteBlockedDate, viewAllBlockedDateTime, deleteAllBlockedDate, getTodayAppointment, viewAllUrgentAppointment, getInitialBookignAppointment, urgentBlockDateTime, viewUrgentBlockdate, viewUrgentblockDateTime, urgentDeleteBlockedDate, urgentDeleteAllBlockedDate
  , userContactDetails
}