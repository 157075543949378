import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import HomeServices from "../../Services/HomeServices";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./All.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import BackPageBtn from "../BackButton/BackPageBtn";

const MoreInfo = () => {
  const [contactData, setContactData] = useState([]);
  const [dateFilter, setDateFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isRescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  console.log(" csvData", dateFilter);
  console.log("contactData", contactData);

  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      customFilterText: (row) => row.date,
    },
    { name: "Name", selector: "firstName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    { name: "Appointment Date", selector: "bookedDate", sortable: true },
    { name: "Appointment Time", selector: "bookedTime", sortable: true },
    { name: "Status", selector: "isStatus", sortable: true },
    { name: "Action", selector: "action", sortable: true },
  ];

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const closeRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };

  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewAppointmentData();
      console.log("Response986532", response);
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          console.log("56465sdbjhksd", ele);
          return {
            sln: index + 1,
            date: moment(ele?.createdAt).utc().format("DD-MM-YYYY"),
            firstName: `${ele?.firstName} ${ele?.lastName}`,
            lastName: ele?.lastName,
            email: ele?.email,
            mobileNumber: ele?.mobileNumber,
            bookedDate: moment(ele?.date).format("DD-MM-YYYY"),
            bookedTime: ele?.BookingTime,
            countryName: ele?.country,
            bookingDate: ele?.date,
            bookingTime: ele?.BookingTime,
            isStatus: (
              <div>
                {ele?.status === false && (
                  <p style={{ color: "red", fontWeight: "bold" }}>Cancelled</p>
                )}

                {ele?.isSchedule === true && (
                  <p style={{ color: "#ff9c00", fontWeight: "bold" }}>
                    Rescheduled
                  </p>
                )}
              </div>
            ),
            action: (
              <>
                <span
                  title="View Appointment Detail"
                  onClick={(e) => handleView(e, ele)}
                >
                  <i
                    className="fa-regular fa-eye"
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      marginRight: "5px",
                      fontSize: "1.5em",
                      color: " #477DA5",
                      backgroundColor: "#E9F3F7",
                    }}
                  ></i>
                </span>

                <span
                  title="Reschedule Appointment"
                  onClick={() => openRescheduleModal(ele)}
                >
                  <i
                    className="fa-solid fa-calendar-days"
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#ff9c00",
                      backgroundColor: "#fee5d2",
                    }}
                  ></i>
                </span>

                <span
                  title="Cancel Appointment"
                  onClick={() => handleViewCancel(ele?._id)}
                >
                  <i
                    className="fa-regular fa-rectangle-xmark"
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#C4554D",
                      backgroundColor: "#FAECEC",
                    }}
                  ></i>
                </span>
              </>
            ),
            // ),
          };
        });
        setContactData(arr);
        setDateFilter(arr)?.split()?.reverse();
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openRescheduleModal = (ele) => {
    console.log("mncxbvjhsg765r567", ele);
    navigate("/reschedule-appointment", {
      state: { id: ele?._id, BookTypeId: ele?.bookingAppointmentService },
    });
    setRescheduleModalOpen(true);
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "1px",
        paddingRight: "1px",
      },
      "SL No.": {
        paddingRight: "4px",
      },
      Date: {
        paddingRight: "4px",
      },
    },
    cells: {
      style: {
        paddingLeft: "1px",
        paddingRight: "8px",
      },
    },
  };

  const handleView = (e, ele) => {
    console.log("56465sdbjhksd9878", ele);
    e.preventDefault();
    setViewId(ele?._id);
    navigate("/view-booked-appointment-data", { state: ele });
    setHighlightedRow(ele._id);
  };

  const handleViewCancel = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel it!",
    }).then((result) => {
      console.log("result", result);
      if (result?.isConfirmed) {
        HomeServices.cancelAppointmentById(id)
          .then((res) => {
            console.log("deleteBlogresponse", res);
            if (res && res.status) {
              toast.success("Appointment Cancelled Successfully");
              fetchData();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((err) => {
            console.error("Error Cancelling Appointment:", err);
          });
      } else {
        console.log("Error while Cancellation");
      }
    });
  };

  const filteredData = contactData.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // const handleDateChange = (e) => {
  //   setSelectedDate(e.target.value);
  // };

  // const filterDataByDate = () => {
  //   const filteredByDate = contactData.filter(
  //     (row) => row.date === formatDate(selectedDate)
  //   );
  //   setDateFilter(filteredByDate);
  // };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // Filter by booking Date

  const filterDataByDateRange = () => {
    const filteredByDateRangeData = contactData?.filter((row) => {
      const rowDate = row?.bookingDate;
      const start = moment(startDate)?.format("YYYY-MM-DD");
      // const end = moment(endDate)?.format("DD-MM-YYYY");
      return rowDate == start;
      // && rowDate <= end;
    });
    console.log("mfngjk87", filteredByDateRangeData);
    setDateFilter(filteredByDateRangeData);
  };

  // CSV File Download
  const prepareDataForCSV = () => {
    if (dateFilter.length > 0) {
      return dateFilter.map((row) => ({
        Name: `${row?.firstName} ${row?.lastName}`,
        Email: row?.email,
        Contact: row?.mobileNumber,
        Country: row.countryName,
        Booking_Date: row?.bookingDate,
        Booking_Time: row?.bookingTime,
      }));
    } else {
      return contactData.map((row) => ({
        Name: row?.firstName,
        Email: row?.email,
        Contact: row?.mobileNumber,
        Country: row?.countryName,
        Booking_Date: row?.bookingDate,
        Booking_Time: row?.bookingTime,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            <div>
              <div className="dateListDiv">
                <h1
                  style={{
                    color: "#ff9c00",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  View Booking Appointments
                </h1>

                <div className="tabRngeDivmain">
                  <div className="strtdivmain">
                    <p className="strttxt">Appointment Date</p>
                    <input
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </div>
                  {/* <span>-</span> */}
                  {/* <div className="strtdivmain">
                    <p className="strttxt">End Date</p>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div> */}
                  <button
                    onClick={filterDataByDateRange}
                    className="Search_icon btn btn-primary"
                    type="button"
                  >
                    Search
                  </button>

                  <CSVLink
                    className="dwdtxtflxdiv"
                    data={prepareDataForCSV()}
                    filename={"table_data.csv"}
                  >
                    <h5>Download CSV File</h5>
                    <i class="fa-regular fa-circle-down"></i>
                  </CSVLink>
                </div>
              </div>
            </div>

            <hr />
            <DataTable
              columns={columns}
              data={dateFilter}
              pagination
              searchable
              customStyles={customStyles}
            />
          </section>
        </div>
      </div>
    </>
  );
};

export default MoreInfo;
