import React, { useEffect, useState } from "react";
import HomeServices from "../../Services/HomeServices";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import moment from "moment";
import BackPageBtn from "../BackButton/BackPageBtn";

const Waitlist_Appointment = () => {
  const [contactData, setContactData] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modal, setModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  console.log("contactData",contactData)
  console.log("dateFilter",dateFilter)

  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    { name: "Date", selector: "date", sortable: true },
    { name: "Name", selector: "firstName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    { name: "Country", selector: "countryName", sortable: true },
    { name: "View", selector: "view", sortable: true },
  ];
  console.log("jsfkbgj9878", contactData);

  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewWaitListAppointment();
      console.log("Response986532", response);
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          console.log("56465sdbjhksd", ele);
          return {
            sln: index + 1,
            date: moment(ele?.createdAt)?.format('DD-MM-YYYY'),
            firstName: `${ele?.firstName} ${ele?.lastName}`,
            email: ele?.email,
            mobileNumber: ele?.mobileNumber,
            countryName: ele?.country, 
            date_range: `${ele?.startDate} / ${ele?.endDate}`,
            appTime:ele?.BookingTime,
            view: (
              <>
                <span
                  title="View Appointment Detail"
                  onClick={(e) => handleView(e, ele)}
                >
                  <i
                    className="fa-regular fa-eye"
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      marginRight: "10px",
                      fontSize: "1.5em",
                      color: " #477DA5",
                      backgroundColor: "#E9F3F7",
                    }}
                  ></i>
                </span>
              </>
            ),
          };
        });
        setContactData(arr);
        setDateFilter(arr);

      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleView = (e, ele) => {
    console.log("56465sdbjhksd9878", ele);
    e.preventDefault();
    setViewId(ele?._id);
    navigate("/view-waitlist-appointment-data", { state: ele });
    setHighlightedRow(ele._id);
  };

  

  const filterDataByDateRange = () => {
    const filteredByDateRangeData = contactData?.filter((row) => {
      const rowDate = row?.date;
      const start = moment(startDate)?.format("DD-MM-YYYY");
      // const end = moment(endDate)?.format("DD-MM-YYYY");
// console.log("fgre34534",row?.date)
      return rowDate == start 
      // && rowDate <= end;
    });
    console.log("mfngjk87", filteredByDateRangeData);
    setDateFilter(filteredByDateRangeData);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    console.log(moment(e.target.value)?.format('DD-MM-YYYY'))
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    console.log(moment(e.target.value)?.format('DD-MM-YYYY'))
  };

  const prepareDataForCSV = () => {
    if (dateFilter.length > 0) {
      return dateFilter.map((row) => ({
        Name: row.firstName,
        Email: row.email,
        Contact: row.mobileNumber,
        Country: row.countryName,
        Date_Range: row.date_range,
        Appointment_Time: row.appTime,
      }));
    } else {
      return contactData.map((row) => ({
        Name: row.firstName,
        Email: row.email,
        Contact: row.mobileNumber,
        Country: row.countryName,
        Date_Range: row.date_range,
        Appointment_Time: row.appTime,
      }));
    }
  };
  
  // const prepareDataForCSV = () => {
  //   if (dateFilter.length > 0) {
  //     return dateFilter?.map((row) => ({
  //       // Name: `${row?.firstName} ${row?.lastName}`,
  //       Name : row?.firstName,
  //       Email: row?.email,
  //       Contact: row?.mobileNumber,
  //       Country: row.countryName,
  //       Date_Range: row?.date_range,
  //       Appointment_Time: row?.appTime,
  //     }));
  //   } else {
  //     return contactData?.map((row) => ({
  //       Name: row?.firstName,
  //       Email: row?.email,
  //       Contact: row?.mobileNumber,
  //       Country: row?.countryName,
  //       Date_Range: row?.date_range,
  //       Appointment_Time: row?.appTime,
  //     }));
  //   }
  // };
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
          {/* <BackPageBtn backLink="/" /> */}

             <div className="dateListDiv">
                <h1
                  style={{
                    color: "#ff9c00",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  View Waitlist Appointments
                </h1>

                {/* <Tab>Any Date</Tab> */}
                {/* <Tab>Select Date Range</Tab> */}

                {/* <TabPanel></TabPanel> */}

                <div className="tabRngeDivmain">
                  <div className="strtdivmain">
                    {/* <label htmlFor="startDate">Start Date</label> */}
                    <p className="strttxt">Start Date</p>
                    <input
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                      // onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  {/* <span>-</span> */}
                  {/* <div className="strtdivmain">
                    <p className="strttxt">End Date</p>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                      // onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div> */}
                  <button
                    onClick={filterDataByDateRange}
                    className="Search_icon btn btn-primary"
                    type="button"
                  >
                    Search
                  </button>

                  <CSVLink
                    className="dwdtxtflxdiv"
                    data={prepareDataForCSV()}
                    filename={"table_data.csv"}
                  >
                    <h5>Download CSV File</h5>
                    <i class="fa-regular fa-circle-down"></i>
                  </CSVLink>
                </div>
              </div>
            <hr />
            <DataTable columns={columns} data={dateFilter} pagination /> 
          </section>
        </div>
      </div>
    </>
  );
};

export default Waitlist_Appointment;
