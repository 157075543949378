import React, { useEffect, useState } from "react";
import HomeServices from "../../Services/HomeServices";
import { Link } from "react-router-dom";

const HomeCard = () => {
  // const card = [
  //   {
  //     id: 1,
  //     color: "rgb(236, 64, 122)",
  //     name: "Budding Talent Count",
  //     link: "/buddingview",
  //     userType: "BuddingTalent",
  //   },
  //   {
  //     id: 2,
  //     color: "rgb(73, 163, 241)",
  //     name: "Influencer",
  //     link: "/influencerview",
  //     userType: "Influencer",
  //   },
  //   {
  //     id: 3,
  //     color: "rgb(102, 187, 106)",
  //     name: "Scout",
  //     link: "/scoutview",
  //     userType: "Scout",
  //   },
  // ];

  const [users, setUsers] = useState([]);
  const [bookedData, setBookedData] = useState([]);
  const [waitListData, setWaitListData] = useState([]);
  useEffect(() => {
    fetchData();
    fetchBookedData();
    fetchWaitlistData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await HomeServices.viewUserContact();
      if (response && response?.status) {
        let arr = response?.data;
        setUsers(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  const fetchBookedData = async () => {
    try {
      const response = await HomeServices.viewAppointmentData();
      if (response && response?.status) {
        let arr = response?.data;
        setBookedData(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  const fetchWaitlistData = async () => {
    try {
      const response = await HomeServices.viewWaitListAppointment();
      console.log("Response98653200", response);
      if (response && response?.status) {
        let arr = response?.data;
        setWaitListData(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  return (
    <>
      <div className="HomeTop">
        <div className="row">
          
          {/* card-1 */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5
                className="HomeCardHead"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Users
              </h5>
              <span
                className="HomeCardPara"
                style={{
                  border: "2px solid #FF9C00",
                  borderRadius: "10px",
                  fontSize: "30px",
                  padding: "5px",
                  backgroundColor: "#f0f0f0",
                  color: "#FF9C00",
                  boxShadow: "0px 0 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                {users?.length}
              </span>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Link
                  to="/view-user-contact"
                  className="HomeCardViewbtn"
                  style={{ textDecoration: "none" }}
                >
                  View
                </Link>
              </div>
              <div
                className="cardIconDiv"
                style={{ backgroundColor: "#FF9C00" }}
              >
                <i class="fa-regular fa-user"></i>
              </div>
            </div>
          </div>

          {/* card-2 */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5
                className="HomeCardHead"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Booking Appointmens
              </h5>
              <span
                className="HomeCardPara"
                style={{
                  border: "2px solid #FF9C00",
                  borderRadius: "10px",
                  fontSize: "30px",
                  padding: "5px",
                  backgroundColor: "#f0f0f0",
                  color: "#FF9C00",
                  boxShadow: "0px 0 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                {bookedData?.length}
              </span>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Link
                  to="/view-user-appointment"
                  className="HomeCardViewbtn"
                  style={{ textDecoration: "none" }}
                >
                  View
                </Link>
              </div>
              <div
                className="cardIconDiv"
                style={{ backgroundColor: "#FF9C00" }}
              >
                {/* <i class="fa-solid fa-book-atlas"></i> */}
                {/* <i class="fa-thin fa-book-bookmark"></i> */}
                <i class="fa-regular fa-user" style={{marginRight:"5px"}}></i>
                <i class="fa-solid fa-book-atlas"></i>
              </div>
            </div>
          </div>

          {/* card-3 */}
          <div className="col-md-4 col-12">
            <div className="HomeCard">
              <h5
                className="HomeCardHead"
                style={{ fontWeight: "bold", marginBottom: "20px" }}
              >
                Wait List Appointments
              </h5>
              <span
                className="HomeCardPara"
                style={{
                  border: "2px solid #FF9C00",
                  borderRadius: "10px",
                  fontSize: "30px",
                  padding: "5px",
                  backgroundColor: "#f0f0f0",
                  color: "#FF9C00",
                  boxShadow: "0px 0 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                {waitListData?.length}
              </span>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Link
                  to="/view-user-waitlist-appointment"
                  className="HomeCardViewbtn"
                  style={{ textDecoration: "none" }}
                >
                  View
                </Link>
              </div>
              <div
                className="cardIconDiv"
                style={{ backgroundColor: "#FF9C00" }}
              > 
               <i class="fa-regular fa-user" style={{marginRight:"5px"}}></i>
               <i class="fa-solid fa-hourglass-half" ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCard;
