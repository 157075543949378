import React from "react";
// import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/logo.png";
import { toast } from "react-hot-toast";

function Sidebar() {
  const navigate = useNavigate();
  const handleClick = () => {
    if (document.querySelector("#DarkSwitch").checked) {
      document.body.classList.add("drakmode");
    } else {
      document.body.classList.remove("drakmode");
    }
  };

  const handlClick = () => {
    if (document.querySelector("#backbtnsidebar").click) {
      document.querySelector(".sidebarwrap").classList.remove("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };

  const handleLogOut = () => {
    // toast.success("Logout SuccessFull");
    alert("vfghas");
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <button id="backbtnsidebar" onClick={handlClick}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>

          <div className="logo_area">
            <div className="logo">
              <img
                src={logo}
                className="img-fluid"
                alt="logo" 
              />
            </div>
          </div>
        </div>
        <div style={{marginTop:"10px"}}>
          <p style={{ color: "#fff", fontWeight:"bold" }}>Desh Videsh Immigration</p>
        </div> 
        <div className="sidebar_menu">
          <ul className="nav Menu_Nav accordion" id="sidemenu">
            {/***Menu 1***/}
            <li className="menuline">
              <NavLink to="/" className="" onClick={handlClick}>
                <i className="fa-solid fa-bars"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {/***Menu 2***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead1">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu1"
                  aria-expanded="true"
                  aria-controls="sidemenu1"
                >
                  {/* <i className="fa-regular fa-address-card"></i> */}
                  <i class="fa-solid fa-pen-to-square"></i>
                  <span>Blog</span>
                </Link>
              </div>
              <div
                id="sidemenu1"
                className="collapse"
                aria-labelledby="sidemenuhead1"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/add-blog" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add Blog
                    </Link>
                  </li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/***Menu 5***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead7">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu7"
                  aria-expanded="true"
                  aria-controls="sidemenu7"
                >
                  {/* <i className="fa-regular fa-address-card"></i> */}
                  <i class="fa-regular fa-address-book"></i>
                  <span>User Contact</span>
                </Link>
              </div>
              <div
                id="sidemenu7"
                className="collapse"
                aria-labelledby="sidemenuhead7"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/view-user-contact" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>View User
                      Contact
                    </Link>
                  </li>
                  {/* <li><Link to="/view-user-email" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Email</Link></li> */}
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/***Menu 5***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead8">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu8"
                  aria-expanded="true"
                  aria-controls="sidemenu8"
                >
                  {/* <i className="fa-regular fa-address-card"></i> */}
                  <i class="fa-solid fa-envelope-open-text"></i>
                  <span>News Letter</span>
                </Link>
              </div>
              <div
                id="sidemenu8"
                className="collapse"
                aria-labelledby="sidemenuhead8"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {/* <li><Link to="/view-user-contact" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>View User Contact</Link></li> */}
                  <li>
                    <Link to="/view-user-email" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>View News
                      Letter
                    </Link>
                  </li>
                  {/* <li><NavLink to="page2" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page3" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li> */}
                </ul>
              </div>
            </li>

            {/***Menu 5***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead6">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu6"
                  aria-expanded="true"
                  aria-controls="sidemenu6"
                >
                  {/* <i className="fa-regular fa-address-card"></i> */}
                  <i class="fa-regular fa-calendar-check fa-lg" ></i>
                  <span>Appointment</span>
                </Link>
              </div>
              <div
                id="sidemenu6"
                className="collapse"
                aria-labelledby="sidemenuhead6"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <Link to="/view-user-appointment" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>View
                      Appointment
                    </Link>
                  </li>

                  <li>
                    <Link to="/view-user-today-appointment" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Schedule
                      Appointment
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/view-user-waitlist-appointment"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>WaitList
                      Appointment
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/view-user-urgent-appointment"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>Urgent
                      Appointment
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/initial-appointment"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>Initial
                      Appointment
                    </Link>
                  </li>
                  {/* /initial-appointment */}
                  <li>
                    <Link
                      to="/view-cancel-appointment-data"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>Cancel
                      Appointment
                    </Link>
                  </li> 

                  <li>
                    <Link
                      to="/block-date-time"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>Block
                      Date Time
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/urgent-block-date-time"
                      onClick={handlClick}
                    >
                      <i className="fa-solid fa-angles-right mr-2"></i>Urgent Block
                      Date Time
                    </Link>
                  </li>

                </ul>
              </div>
            </li>

            {/***Menu 3***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead2">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu2"
                  aria-expanded="true"
                  aria-controls="sidemenu2"
                >
                  <i className="fa-solid fa-user-tie"></i>
                  <span>Manage</span>
                </Link>
              </div>
              <div
                id="sidemenu2"
                className="collapse"
                aria-labelledby="sidemenuhead2"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="page4" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 1</NavLink></li>
                  <li><NavLink to="page5" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page6" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li>
                </ul>
              </div>
            </li>  */}

            {/***Menu 4***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead3">
                <Link to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu3"
                  aria-expanded="true"
                  aria-controls="sidemenu3"
                >
                  <i className="fa-solid fa-subscript"></i>
                  <span>Subscription</span>
                </Link>
              </div>
              <div
                id="sidemenu3"
                className="collapse"
                aria-labelledby="sidemenuhead3"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li><NavLink to="page7" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 1</NavLink></li>
                  <li><NavLink to="page8" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 2</NavLink></li>
                  <li><NavLink to="page9" onClick={handlClick}><i className="fa-solid fa-angles-right mr-2"></i>Sub Menu 3</NavLink></li>
                </ul>
              </div>
            </li> */}
          </ul>

          <ul className="nav Account_Nav">
            <div onClick={handleLogOut} style={{ width: "100%" }}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
