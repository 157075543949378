import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/Component/Modal/Modal.css";
import Home from "../src/View/Home/Index";
import Login from "./Athentication/Login";

import MainLayout from "./Layout/MainLayout";
import PrivateRouter from "./privateRouter/PrivateRouter";
import AdBlog from "./Pages/Blog/AddBlog";
import View_Blog from "./Pages/Blog/View_Blog";
import ViewUserDetail from "./Pages/UserContact/ViewUserDetail";
import View_User_Contact from "./Pages/UserContact/View_User_Contact";
import UserEmail from "./Pages/UserContact/UserEmail";
import MoreInfo from "./Pages/Appointment/MoreInfo";
import Waitlist_Appointment from "./Pages/Appointment/Waitlist_Appointment";
import View_booked_Appointment_detail from "./Pages/Appointment/View_booked_Appointment_detail";
import View_Waitlist_Appointment_Data from "./Pages/Appointment/View_Waitlist_Appointment_Data";
import CancelAppointment from "./Pages/Appointment/CancelAppointment";
import NewRes from "./Pages/Appointment/NewRes";
import NewBlockDateTime from "./Pages/Appointment/NewBlockDateTime";
import Res from "./Pages/Appointment/Res";
import TodayAppointment from "./Pages/Appointment/TodayAppointment";
import UrgentAppointment from "./Pages/Appointment/UrgentAppointment";
import ViewUrgentApointmentData from "./Pages/Appointment/ViewUrgentApointmentData";
import UrgentBlockDateTime from "./Pages/Appointment/UrgentBlockDateTime";
import InitialAppointment from "./Pages/Appointment/InitialAppointment";
import UregentReschedule from "./Pages/Appointment/UregentReschedule";
import OwnProfile from "./Pages/OwnProfile/OwnProfile";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>

          <Route element={<PrivateRouter />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/add-blog" element={<AdBlog />} />
              <Route path="/view-blog" element={<View_Blog />} />
              <Route path="/view-user-contatc-detail" element={<ViewUserDetail />} />
              <Route path="/view-user-contact" element={<View_User_Contact />} />
              <Route path="/view-user-email" element={<UserEmail />} />
              <Route path="/view-user-appointment" element={<MoreInfo />} />
              <Route path="/view-user-today-appointment" element={<TodayAppointment />} />
              <Route path="/view-user-urgent-appointment" element={<UrgentAppointment />} />
              <Route path="/view-urgent-booked-appointment-data" element={<ViewUrgentApointmentData />} />
              <Route path="/view-booked-appointment-data" element={<View_booked_Appointment_detail />} />
              <Route path="/view-user-waitlist-appointment" element={<Waitlist_Appointment />} />
              <Route path="/view-waitlist-appointment-data" element={<View_Waitlist_Appointment_Data />} />
              <Route path="/view-cancel-appointment-data" element={<CancelAppointment />} />
              <Route path="/block-date-time" element={<NewBlockDateTime />} />
              <Route path="/newres-reschedule-appointment" element={<NewRes />} />
              <Route path="/reschedule-appointment" element={<Res />} />
              <Route path="/urgent-reschedule-appointment" element={<UregentReschedule />} />
              <Route path="/urgent-block-date-time" element={<UrgentBlockDateTime />} />
              <Route path="/initial-appointment" element={<InitialAppointment />} />
              <Route path="/profile" element={<OwnProfile />} />
            </Route>
          </Route>
          {/*UregentReschedule  */}
          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
