import React, { useEffect, useState } from "react";
import HomeServices from "../../Services/HomeServices"; 
import DataTable from "react-data-table-component";
import BackPageBtn from "../BackButton/BackPageBtn";


const UserEmail = () => {
  const [userEmailData, setUserEmailData] = useState(); 
  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    { name: "Email ID", selector: "email", sortable: true }
  ];
  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewUserEmail();
      console.log("hvhg8798dcjs", response);
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          return {
            sln: index + 1,
            email: ele?.email,
          };
        });
        setUserEmailData(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
          {/* <BackPageBtn backLink="/" /> */}

            <h1 style={{color:"#ff9c00"}}>View User Email</h1>
            <hr />
            <DataTable columns={columns} data={userEmailData} pagination/>
          </section>
        </div>
      </div> 
    </>
  );
};

export default UserEmail;
